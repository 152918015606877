import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from './../../app.config';
import { GeozoneRequest } from './../../shared/models/dto/request/add-geozone.model';
import { Geozone } from './../../shared/models/geozone.model';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  get(layer_id: string): Observable<Geozone[]> {
    const params = new HttpParams().set('limit', '99');
    return this.http
      .get(this.config.get('baseUrl') + `api/geolayers/${layer_id}/geozones`, { params })
      .pipe(map(res => <Geozone[]>res['results']));
  }

  put(zone: Geozone): Observable<any> {
    return this.http
      .put(this.config.get('baseUrl') + `api/geolayers/${zone.geolayerId}/geozones/${zone._id}`, zone)
      .pipe(map(res => zone));
  }

  createRandom(payload: GeozoneRequest): Observable<Geozone> {
    const code = (Math.random() * 12345678).toString(16);
    return this.http
      .post(this.config.get('baseUrl') + `api/geolayers/${payload.layer_id}/geozones`, {
        code: code.toUpperCase(),
        label: code,
        geometry: payload.geometry,
        type: payload.geometry.type.toUpperCase(),
        color: payload.color,
        weight: 1
      })
      .pipe(map(res => <Geozone>res));
  }

  delete(zone: Geozone): Observable<any> {
    return this.http
      .delete(this.config.get('baseUrl') + `api/geolayers/${zone.geolayerId}/geozones/${zone._id}`)
      .pipe(map(res => zone));
  }
}
