import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-gis-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
  @Input()
  value = false;

  @Output()
  switch = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  onChange() {
    this.switch.emit(!this.value);
  }
}
