import { RuleParams } from './../rule-params.model';

export class EmailAction implements RuleParams {
  public static CODE = 'Email';

  code = EmailAction.CODE;
  options = [
    {
      key: 'recipientsTo',
      type: 'multi_text',
      value: []
    },
    {
      key: 'recipientsCc',
      type: 'multi_text',
      value: []
    },
    {
      key: 'recipientsBcc',
      type: 'multi_text',
      value: []
    },
    {
      key: 'subjectText',
      type: 'text',
      value: ''
    },
    {
      key: 'bodyText',
      type: 'textarea',
      value: ''
    }
  ];

  constructor(public id: string = '', params: RuleParams[] = null) {
    if (params) {
      for (const opt of this.options) {
        opt.value = params[opt.key];
      }

      console.log('Done initializing options', this.options);
    }
  }

  validate(): boolean {
    let ok = true;
    for (const option of this.options) {
      switch (option.key) {
        case 'recipientsTo':
          if (!this.checkForValidEmails(option.value)) {
            ok = false;
          }
          break;
        case 'recipientsCc':
        case 'recipientsBcc':
          if (option.value && option.value.length > 0 && !this.checkForValidEmails(option.value)) {
            ok = false;
          }
          break;
        case 'subjectText':
        case 'bodyText':
          if (option.value === '') {
            ok = false;
          }
          break;
      }
    }

    return ok;
  }

  getParameters(): any {
    const out = {};
    for (const opt of this.options) {
      switch (opt.key) {
        default:
          out[opt.key] = opt.value;
      }
    }

    return out;
  }

  checkForValidEmails(values): boolean {
    const regex = new RegExp(
      // regex rule for an email, found at https://emailregex.com/
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );
    for (const email of values) {
      if (!regex.test(email)) {
        return false;
      }
    }
    return true;
  }
}
