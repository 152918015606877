import { UserLoginRequest } from './../../shared/models/dto/request/user-login.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginForm')
  loginForm;

  user = new UserLoginRequest();
  errorMessage: string = null;

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {}

  onSubmitClick(event) {
    this.auth.requestToken(this.user).subscribe(
      res => {
        this.router.navigateByUrl('/admin');
      },
      err => {
        this.errorMessage = err.error.error_description;
      }
    );
  }
}
