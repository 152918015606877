import { HttpClient } from '@angular/common/http';
import { AppConfig } from './../../app.config';
import { UserLoginRequest } from './../../shared/models/dto/request/user-login.model';
import { Injectable } from '@angular/core';
import { isNull } from 'util';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TokenResponse } from '../../shared/models/dto/response/token.model';
import { UserResponse } from '../../shared/models/dto/response/user.model';

const tokenKey = 'token';
const userKey = 'user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken = null;

  constructor(private http: HttpClient, private config: AppConfig) {
    this.accessToken = localStorage.getItem(tokenKey);
  }

  requestToken(user: UserLoginRequest): Observable<any> {
    const body = new FormData();
    body.append('grant_type', 'password');
    body.append('client_id', this.config.get('clientId'));
    body.append('client_secret', this.config.get('clientSecret'));
    body.append('username', user.username);
    body.append('password', user.password);

    // get token in map and store this token in session
    return this.http.post<TokenResponse>(`${this.config.get('baseUrl')}oauth/v2/token`, body).pipe(
      map(res => res.access_token),
      tap(token => {
        localStorage.setItem(tokenKey, token);
        localStorage.setItem(userKey, user.username);
        this.accessToken = token;
      })
    );
  }

  getToken(): string {
    return this.accessToken;
  }

  hasAuth(): boolean {
    return !isNull(this.accessToken);
  }

  getUserInfo(): Observable<UserResponse> {
    return this.http.get(this.config.get('baseUrl') + `api/me`).pipe(map(res => <UserResponse>res));
  }

  clear() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(userKey);
    this.accessToken = null;
  }
}
