import { AuthService } from './../../core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { Router } from '@angular/router';
import { UserResponse } from '../models/dto/response/user.model';

@Component({
  selector: 'app-gis-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  user: UserResponse;
  gravatarUrl: string;
  isOpen = true;

  nav = [
    {
      title: 'Geolayers',
      url: '/admin'
    },
    {
      title: 'Rules',
      url: '/admin/rules'
    }
  ];

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.auth.getUserInfo().subscribe(user => {
      const hash = new Md5().appendStr(user.email.toLowerCase()).end();
      this.gravatarUrl = `https://gravatar.com/avatar/${hash}?size=200&default=mp`;
      this.user = user;
    });
  }

  onNavigateTo(url) {
    this.router.navigateByUrl(url);
  }

  onLoggingOut(event) {
    this.auth.clear();
    this.router.navigateByUrl('/login');
  }
}
