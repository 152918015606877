import { RuleParams } from '../rule-params.model';

export class IncomingMessageTrigger implements RuleParams {
  public code = 'IncomingMessage';
  public parameters = null;
  public options = null;

  constructor(public id: string = '') {}

  validate(): boolean {
    return true;
  }

  getParameters(): any {
    return [];
  }
}
