import { Geolayer } from '../../../shared/models/geolayer.model';
import * as LayerActions from '../actions/layer.actions';
import { layer } from '../types/layer.types';

export function layerReducer(state: Geolayer[] = [], action: LayerActions.ALL) {
  switch (action.type) {
    case layer.ADD_SUCCESS:
      action.payload.zones = [];
      return [...state, action.payload];
    case layer.ADD_FAIL:
      console.error(action['payload']);
      return state;
    case layer.DELETE_SUCCESS:
      return state.filter(l => l._id !== action['payload']);
    case layer.DELETE_FAIL:
      console.error(action['payload']);
      return state;
    case layer.LOAD_SUCCESS:
      return action.payload.map(l => {
        if (!l.zones) {
          l.zones = [];
        }
        return l;
      });
    case layer.LOAD_FAIL:
      console.error(action['payload']);
      return state;
    case layer.ADD_ZONE_SUCCESS:
      return state.map(l => {
        if (l._id === action['payload'].geolayerId) {
          l.zones = [...l.zones, action['payload']];
        }
        return l;
      });
    case layer.ADD_ZONE_FAIL:
      console.error(action['payload']);
      return state;
    case layer.UPDATE_SUCCESS:
      return state.map(l => (l._id === action['payload']._id ? action['payload'] : l));
    case layer.UPDATE_FAIL:
      console.error(action['payload']);
      return state;
    case layer.LOAD_ZONES_SUCCESS:
      return state.map(l => {
        if (action.payload.length > 0 && l._id === action.payload[0].geolayerId) {
          l.zones = action.payload;
        }
        return l;
      });
    case layer.LOAD_ZONES_FAIL:
      console.error(action['payload']);
      return state;
    case layer.SAVE_ZONE_SUCCESS:
      return state.map(l => {
        if (l._id === action.payload.geolayerId) {
          l.zones = l.zones.map(z => (z._id === action.payload._id ? action.payload : z));
          console.log('Updated zone', action.payload._id);
        }
        return l;
      });
    case layer.SAVE_ZONE_FAIL:
      console.error(action['payload']);
      return state;
    case layer.DELETE_ZONE_SUCCESS:
      return state.map(l => {
        if (l._id === action['payload'].geolayerId) {
          l.zones = l.zones.filter(z => z._id !== action['payload']._id);
        }

        return l;
      });
    case layer.DELETE_ZONE_FAIL:
      console.error(action['payload']);
      return state;
    default:
      return state;
  }
}
