import { RuleParams } from './../../models/rule-params.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-gis-add-params-button',
  templateUrl: './add-params-button.component.html',
  styleUrls: ['./add-params-button.component.scss']
})
export class AddParamsButtonComponent implements OnInit {
  @Input()
  type: string;

  @Input()
  options: Option[] = [];

  @Output()
  selected = new EventEmitter<RuleParams>();

  constructor() {}

  ngOnInit() {}

  onSelected(option: Option) {
    this.selected.emit(new option.type());
  }
}

export interface Option {
  name: string;
  type: any;
}
