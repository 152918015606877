import {
  AddLayerAction,
  DeleteLayerAction,
  UpdateLayerAction,
  DeleteGeozoneAction
} from './../core/store/actions/layer.actions';
import { Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { LoadLayersAction, LoadGeozonesAction, SaveGeozoneAction } from '../core/store/actions/layer.actions';
import { Geolayer } from './../shared/models/geolayer.model';
import { Geozone } from './../shared/models/geozone.model';
import { MapComponent } from './map/map.component';

@Component({
  selector: 'app-gis-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild(MapComponent)
  mapComponent: MapComponent;

  @ViewChild('editZoneModal')
  zoneModal: any;
  zoneToEdit: Geozone = null;

  @ViewChild('editLayerModal')
  layerModal: any;
  layerToEdit: Geolayer = null;

  selectedLayer: Geolayer = null;
  layers: Geolayer[];

  soloLayers = false;

  changes: Map<string, Geozone>;

  constructor(private store: Store<AppState>) {
    this.store.pipe(select(s => s.layers)).subscribe(layers => {
      this.layers = <Geolayer[]>layers;
    });
  }

  ngOnInit() {
    this.changes = new Map();
    this.store.dispatch(new LoadLayersAction());
  }

  onToggleLayer(layer: Geolayer) {
    if (this.selectedLayer === layer) {
      this.selectedLayer = null;
    } else {
      this.selectedLayer = layer;
    }
  }

  onZoneSelected(zone: Geozone) {
    this.mapComponent.zoomToZone(zone);
  }

  onEditZone(zone: Geozone) {
    this.zoneToEdit = zone;
    this.zoneModal.open();
  }

  saveZone(zone: Geozone) {
    this.layers = this.layers.map(l => (l === this.selectedLayer ? this.selectedLayer : l));
    this.store.dispatch(new SaveGeozoneAction(zone));
    // this.onAddToChanged(zone);
    this.zoneModal.close();
  }

  deleteZone(zone: Geozone) {
    if (confirm(`Remove zone '${zone.label}'?`)) {
      this.store.dispatch(new DeleteGeozoneAction(zone));
      this.zoneToEdit = {} as Geozone;
      this.changes.delete(zone._id);
      this.zoneModal.close();
    }
  }

  onAddLayer() {
    this.store.dispatch(new AddLayerAction());
  }

  onEditLayer(layer: Geolayer) {
    this.layerToEdit = layer;
    this.layerModal.open();
  }

  onSaveLayer() {
    this.store.dispatch(new UpdateLayerAction(this.layerToEdit));
    this.layerToEdit = {} as Geolayer;
    this.layerModal.close();
  }

  onDeleteLayer() {
    if (confirm('Are you sure you want to delete this layer?')) {
      this.store.dispatch(new DeleteLayerAction(this.layerToEdit._id));
      this.layerToEdit = {} as Geolayer;
      this.layerModal.close();
    }
  }

  addMode() {
    this.mapComponent.addMode();
  }

  onReset() {
    if (confirm('Sure you want to reset?')) {
      this.selectedLayer = null;
      this.ngOnInit();
    }
  }

  onAddToChanged(zone: Geozone) {
    this.changes.set(zone._id, zone);
  }

  saveChanges() {
    this.changes.forEach(zone => {
      this.store.dispatch(new SaveGeozoneAction(zone));
      this.changes.delete(zone._id);
    });
  }

  onToggleSoloLayers(event) {
    this.soloLayers = event;
  }
}
