import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Rule } from './../models/rule.model';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DeleteRuleAction, LoadRulesAction } from '@store/actions/rule.actions';
import { rule as ruleTypes } from '@store/types/rule.types';
import { Observable, Subject } from 'rxjs';
import { tap, map, takeUntil } from 'rxjs/operators';
import { AppState } from './../../app.state';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gis-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit, OnDestroy {
  rules$: Observable<Rule[]>;
  destroyed$ = new Subject<boolean>();

  hasRetried = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private alertService: ToastrService,
    private actions$: Actions
  ) {
    this.rules$ = store.pipe(
      select(s => s.rules),
      takeUntil(this.destroyed$),
      tap(rules => {
        if (!rules || rules.length <= 0) {
          if (!this.hasRetried) {
            this.store.dispatch(new LoadRulesAction());
            this.hasRetried = true;
          } else {
            console.log('Stopped retrying');
            this.alertService.info('Refresh later to try again or add one now!..', 'No rules found.');
          }
        }
      })
    );

    // subscribe to Delete Rule Actions
    this.actions$
      .pipe(
        ofType(ruleTypes.DELETE_SUCCESS),
        takeUntil(this.destroyed$),
        map(res =>
          this.alertService.success('Successfully removed rule "' + res['payload'] + '".', 'Removal successful!')
        )
      )
      .subscribe();
    this.actions$
      .pipe(
        ofType(ruleTypes.DELETE_FAIL),
        takeUntil(this.destroyed$),
        map(() => this.alertService.error('Rule could not be removed.'))
      )
      .subscribe();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  editRule(ruleId: string) {
    this.router.navigateByUrl('/admin/rules/' + ruleId);
  }

  deleteRule(rule: Rule) {
    this.store.dispatch(new DeleteRuleAction(rule.id));
  }
}
