import { GeozoneRequest } from './../../../shared/models/dto/request/add-geozone.model';
import { Action } from '@ngrx/store';
import { layer } from '../types/layer.types';
import { Geolayer } from '../../../shared/models/geolayer.model';
import { Geozone } from '../../../shared/models/geozone.model';

export class AddLayerAction implements Action {
  readonly type = layer.ADD;

  constructor(public payload?: any) {}
}

export class AddSuccessAction implements Action {
  readonly type = layer.ADD_SUCCESS;

  constructor(public payload: Geolayer) {}
}

export class AddFailedAction implements Action {
  readonly type = layer.ADD_FAIL;

  constructor(public payload?: any) {}
}

export class DeleteLayerAction implements Action {
  readonly type = layer.DELETE;

  constructor(public payload: string) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = layer.DELETE_SUCCESS;

  constructor(public payload?: any) {}
}

export class DeleteFailedAction implements Action {
  readonly type = layer.DELETE_FAIL;

  constructor(public payload?: any) {}
}

export class LoadLayersAction implements Action {
  readonly type = layer.LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccessAction implements Action {
  readonly type = layer.LOAD_SUCCESS;

  constructor(public payload: Geolayer[]) {}
}

export class LoadFailedAction implements Action {
  readonly type = layer.LOAD_FAIL;

  constructor(public payload?: any) {}
}
export class UpdateLayerAction implements Action {
  readonly type = layer.UPDATE;

  constructor(public payload: Geolayer) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = layer.UPDATE_SUCCESS;

  constructor(public payload: Geolayer) {}
}

export class UpdateFailedAction implements Action {
  readonly type = layer.UPDATE_FAIL;

  constructor(public payload?: any) {}
}

export class AddGeozoneAction implements Action {
  readonly type = layer.ADD_ZONE;

  constructor(public payload: GeozoneRequest) {}
}

export class AddGeozoneSuccessAction implements Action {
  readonly type = layer.ADD_ZONE_SUCCESS;

  constructor(public payload: Geozone) {}
}

export class AddGeozoneFailedAction implements Action {
  readonly type = layer.ADD_ZONE_FAIL;

  constructor(public payload?: any) {}
}

export class LoadGeozonesAction implements Action {
  readonly type = layer.LOAD_ZONES;

  constructor(public payload: string /* id */) {}
}
export class LoadGeozonesSuccessAction implements Action {
  readonly type = layer.LOAD_ZONES_SUCCESS;

  constructor(public payload: Geozone[]) {}
}
export class LoadGeozonesFailedAction implements Action {
  readonly type = layer.LOAD_ZONES_FAIL;

  constructor(public payload?: any) {}
}

export class SaveGeozoneAction implements Action {
  readonly type = layer.SAVE_ZONE;

  constructor(public payload: Geozone) {}
}
export class SaveGeozoneSuccessAction implements Action {
  readonly type = layer.SAVE_ZONE_SUCCESS;

  constructor(public payload: Geozone) {}
}

export class SaveGeozoneFailedAction implements Action {
  readonly type = layer.SAVE_ZONE_FAIL;

  constructor(public payload?: any) {}
}

export class DeleteGeozoneAction implements Action {
  readonly type = layer.DELETE_ZONE;

  constructor(public payload: Geozone) {}
}
export class DeleteGeozoneSuccessAction implements Action {
  readonly type = layer.DELETE_ZONE_SUCCESS;

  constructor(public payload: Geozone) {}
}

export class DeleteGeozoneFailedAction implements Action {
  readonly type = layer.DELETE_ZONE_FAIL;

  constructor(public payload?: any) {}
}

export type ALL =
  | AddLayerAction
  | AddSuccessAction
  | AddFailedAction
  | DeleteLayerAction
  | DeleteSuccessAction
  | DeleteFailedAction
  | LoadLayersAction
  | LoadSuccessAction
  | LoadFailedAction
  | UpdateLayerAction
  | UpdateSuccessAction
  | UpdateFailedAction
  | LoadGeozonesAction
  | LoadGeozonesSuccessAction
  | LoadGeozonesFailedAction
  | AddGeozoneAction
  | AddGeozoneSuccessAction
  | AddGeozoneFailedAction
  | SaveGeozoneAction
  | SaveGeozoneSuccessAction
  | SaveGeozoneFailedAction
  | DeleteGeozoneAction
  | DeleteGeozoneSuccessAction
  | DeleteGeozoneFailedAction;
