import { Rule } from '../../../rules/models/rule.model';
import * as RuleActions from '../actions/rule.actions';
import { rule } from './../types/rule.types';

export function ruleReducer(state: Rule[] = [], action: RuleActions.ALL) {
  switch (action.type) {
    case rule.LOAD_SUCCESS:
      return action.payload;
    case rule.ADD_SUCCESS:
      return [...state, action.payload];
    case rule.UPDATE_SUCCESS:
      return state.map(r => (r.id === action.payload.id ? action.payload : r));
    case rule.DELETE_SUCCESS:
      return state.filter(s => s.id !== action.payload);
    default:
      return state;
  }
}
