import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AppConfig {
  public config: Object = null;

  constructor(private injector: Injector) {
    // we need the injector in stead of HttpClient to not get in trouble with HTTP_INTERCEPTOR, i think
  }

  public get(key: string): any {
    return this.config[key];
  }

  public load(): Promise<any> {

    return new Promise(resolve => {

      this.injector.get(HttpClient).get('/assets/config.json')
        .subscribe(res => {
          this.config = res;
          resolve(true);
        });

    });
  }

}
