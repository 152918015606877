export const layer = {
  ADD: '[LAYER] add',
  ADD_SUCCESS: '[LAYER] add successful',
  ADD_FAIL: '[LAYER] add FAILED',

  DELETE: '[LAYER] delete',
  DELETE_SUCCESS: '[LAYER] delete successful',
  DELETE_FAIL: '[LAYER] delete FAILED',

  LOAD: '[LAYER] load',
  LOAD_SUCCESS: '[LAYER] load successful',
  LOAD_FAIL: '[LAYER] load FAILED',

  UPDATE: '[LAYER] update',
  UPDATE_SUCCESS: '[LAYER] update successful',
  UPDATE_FAIL: '[LAYER] update FAILED',

  ADD_ZONE: '[LAYER] add zone',
  ADD_ZONE_SUCCESS: '[LAYER] add zone successful',
  ADD_ZONE_FAIL: '[LAYER] add zone FAILED',

  LOAD_ZONES: '[LAYER] load zones',
  LOAD_ZONES_SUCCESS: '[LAYER] load zones successful',
  LOAD_ZONES_FAIL: '[LAYER] load zones FAILED',

  SAVE_ZONE: '[LAYER] save zone',
  SAVE_ZONE_SUCCESS: '[LAYER] save zone successful',
  SAVE_ZONE_FAIL: '[LAYER] save zone FAILED',

  DELETE_ZONE: '[LAYER] delete zone',
  DELETE_ZONE_SUCCESS: '[LAYER] delete zone successful',
  DELETE_ZONE_FAIL: '[LAYER] delete zone FAILED'
};
