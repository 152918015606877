export const user = {
  ADD: '[USER] add',
  ADD_SUCCESS: '[USER] add successful',
  ADD_FAIL: '[USER] add FAILED',

  DELETE: '[USER] delete',
  DELETE_SUCCESS: '[USER] delete successful',
  DELETE_FAIL: '[USER] delete FAILED',

  LOAD: '[USER] load',
  LOAD_SUCCESS: '[USER] load successful',
  LOAD_FAIL: '[USER] load FAILED',

};
