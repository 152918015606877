import { AddFailedAction } from './../../core/store/actions/rule.actions';
import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from './../../app.state';
import { rule as ruleTypes } from '@store/types/rule.types';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AddRuleAction, LoadRulesAction, UpdateRuleAction } from '@store/actions/rule.actions';
import { from, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EmailAction } from '../models/actions/email-action.model';
import { GeofenceCondition } from '../models/conditions/geofence-condition.model';
import { IncomingMessageTrigger } from '../models/triggers/incoming-message-trigger.model';
import { Rule } from '../models/rule.model';
import { RuleParams } from '../models/rule-params.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gis-rule-edit',
  templateUrl: './rule-edit.component.html',
  styleUrls: ['./rule-edit.component.scss']
})
export class RuleEditComponent implements OnInit, OnChanges, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  _id: string;

  rule: Rule;
  hasRetried = false;

  addOptions = {
    triggers: [
      {
        name: 'Incoming Message',
        type: IncomingMessageTrigger
      }
    ],
    conditions: [
      {
        name: 'Geofence',
        type: GeofenceCondition
      }
    ],
    actions: [
      {
        name: 'Send Email',
        type: EmailAction
      }
    ]
  };

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    router: Router,
    private alertService: ToastrService,
    actions$: Actions
  ) {
    // subscribe to CREATE events
    actions$
      .pipe(
        ofType(ruleTypes.ADD_SUCCESS),
        takeUntil(this.destroyed$),
        tap(res =>
          this.alertService.success(
            'Successfully created rule "' + res['payload']['label'] + '".',
            'Creation successful!'
          )
        ),
        tap(res => router.navigateByUrl('rules/' + res['payload']['id']))
      )
      .subscribe();
    actions$
      .pipe(
        ofType(ruleTypes.ADD_FAIL),
        takeUntil(this.destroyed$),
        tap(res => this.alertService.error('Failed to create rule.'))
      )
      .subscribe();

    // subscribe to UPDATE events
    actions$
      .pipe(
        ofType(ruleTypes.UPDATE_SUCCESS),
        takeUntil(this.destroyed$),
        tap(res =>
          this.alertService.success(
            'Successfully updated rule "' + res['payload']['label'] + '".',
            'Update successful!'
          )
        )
      )
      .subscribe();
    actions$
      .pipe(
        ofType(ruleTypes.UPDATE_FAIL),
        takeUntil(this.destroyed$),
        tap(res => this.alertService.error('Failed to update rule.'))
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => this.init(params['id']));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  init(id: string) {
    if (id === '0') {
      this.rule = {
        label: '',
        triggers: [],
        conditions: [],
        actions: []
      } as Rule;
    } else {
      this.store
        .pipe(
          select(s => s.rules),
          takeUntil(this.destroyed$),
          tap(rules => {
            if (!rules || rules.length <= 0) {
              if (!this.hasRetried) {
                this.store.dispatch(new LoadRulesAction());
                this.hasRetried = false;
              }
            }
          }),
          switchMap(rules => from(rules)),
          filter(r => r.id === id)
        )
        .subscribe(r => (this.rule = r));
    }
  }

  removeFromArray(theArray, theElement: any) {
    theArray.splice(theArray.indexOf(theElement, null), 1);
  }

  save() {
    // first check if everything is as it should be
    if (this.checkForms()) {
      if (this.rule.id) {
        this.update();
      } else {
        this.create();
      }
    }
  }

  create() {
    this.store.dispatch(new AddRuleAction(this.rule));
  }

  update() {
    this.store.dispatch(new UpdateRuleAction(this.rule));
  }

  checkForms(): boolean {
    let ok = true;

    if (!this.rule.label || this.rule.label === '') {
      this.alertService.error('Please fill in the label', 'Form Error');
      ok = false;
    }

    if (!this.rule.unitGroup || this.rule.unitGroup === '') {
      this.alertService.error('Please fill in the unitGroup', 'Form Error');
      ok = false;
    }

    for (const check of ['triggers', 'conditions', 'actions']) {
      if (!this.checkCollection(this.rule[check])) {
        ok = false;
      }
    }

    return ok;
  }

  checkCollection(coll: RuleParams[]): boolean {
    for (const item of coll) {
      if (!item.validate()) {
        this.alertService.error(item.code + ' is not correct', 'Form Error');
        return false;
      }
    }

    return true;
  }
}
