import { RuleParams } from '../../../../rules/models/rule-params.model';

export class RuleParamsDto {
  public code: string;
  public parameters = [];

  constructor(params: RuleParams) {
    this.code = params.code;
    this.parameters = params.getParameters();
    // if (params.options) {
    //   for (const opts of params.options) {
    //     this.parameters.push(opts.getParameters());
    //   }
    // }
  }

  static fromArray(params: RuleParams[]): RuleParamsDto[] {
    const out = [];

    for (const param of params) {
      out.push(new RuleParamsDto(param));
    }

    return out;
  }
}
