import { RuleResponseDto } from './../../shared/models/dto/response/rule.models';
import { AppConfig } from './../../app.config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Rule } from '../../rules/models/rule.model';
import { ObserveOnMessage } from 'rxjs/internal/operators/observeOn';
import { RuleRequestDto } from '../../shared/models/dto/request/rule.model';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RuleService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  get(): Observable<Rule[]> {
    return this.http
      .get(this.config.get('baseUrl') + 'api/rules')
      .pipe(map(res => RuleResponseDto.toRuleArray(res['results'])));
  }

  getFromUrl(): Observable<any> {
    return this.http.get(this.config.get('baseUrl') + 'api/rules');
  }

  getById(id: string): Observable<Rule> {
    return this.http.get<Rule>('assets/api/rules/' + id + '.json');
  }

  post(rule: Rule): Observable<Rule> {
    return this.http
      .post<RuleResponseDto>(this.config.get('baseUrl') + 'api/rules', new RuleRequestDto(rule))
      .pipe(map(dto => RuleResponseDto.toRule(dto)));
  }

  put(rule: Rule): Observable<Rule> {
    return this.http
      .put<RuleResponseDto>(this.config.get('baseUrl') + 'api/rules/' + rule.id, new RuleRequestDto(rule))
      .pipe(map(_ => rule));
  }

  delete(ruleId: string): Observable<string> {
    return this.http.delete(this.config.get('baseUrl') + 'api/rules/' + ruleId).pipe(map(res => ruleId));
  }

  enable(id: string, enable: boolean): Observable<Rule> {
    return this.http.patch<Rule>(this.config.get('baseUrl') + 'api/rules/' + id, {
      enabled: enable
    });
  }
}
