import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../../usermanagement/users/user.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfig} from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private appConfig: AppConfig) {
  }

  get(): Observable<User[]> {
    return this.http.get(this.appConfig.get('api_url') + 'MOCK_DATA.json').pipe(
      map(res => {
        return <User[]>res;
      })
    );
  }
}
