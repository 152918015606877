import { EmailAction } from './../../../../rules/models/actions/email-action.model';
import { IncomingMessageTrigger } from './../../../../rules/models/triggers/incoming-message-trigger.model';
import { RuleParams } from '../../../../rules/models/rule-params.model';
import { GeofenceCondition } from '../../../../rules/models/conditions/geofence-condition.model';

export class RuleParamsResponseDto {
  _id: string;
  code: string;
  parameters = [];

  static toArray(params: RuleParamsResponseDto[]): RuleParams[] {
    const out = [];

    for (const param of params) {
      out.push(RuleParamsResponseDto.to(param));
    }

    return out;
  }

  // FIXME: fix the switch case
  static to(params: RuleParamsResponseDto): RuleParams {
    switch (params.code) {
      case GeofenceCondition.CODE:
        return new GeofenceCondition(params._id, params.parameters);
      case EmailAction.CODE:
        return new EmailAction(params._id, params.parameters);
      default:
        return new IncomingMessageTrigger(params._id);
    }
  }

  static matchOptions(params: any[]): any {
    return {
      type: 'text'
    };
  }
}
