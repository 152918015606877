import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/auth-guard.service';
import { RulesComponent } from './rules/rules.component';
import { RuleEditComponent } from './rule-edit/rule-edit.component';
import { DashboardComponent } from '../shared/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'admin/rules',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: RulesComponent
      },
      {
        path: ':id',
        component: RuleEditComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RulesRoutingModule {}
