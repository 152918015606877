import { Geolayer } from './../../../shared/models/geolayer.model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { map, tap } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { isNull } from 'util';

@Component({
  selector: 'app-gis-rule-typeahead',
  templateUrl: './rule-typeahead.component.html',
  styleUrls: ['./rule-typeahead.component.scss']
})
export class RuleTypeaheadComponent implements OnInit {
  model: any = [];

  @Input()
  initial: any;

  @Input()
  endpoint: string = null;

  @Input()
  multi = true;

  @Input()
  suggestions = [];

  @Input()
  idField = '_id';

  @Input()
  nameField = 'label';

  @Input()
  allowCustomValues = false;

  @Input()
  set removed(value: string) {
    if (!isNull(value)) {
      this.model = this.model.filter(m => m !== value);
      this.remove.emit(value);
    }
  }

  @Output()
  select = new EventEmitter<string[]>();

  @Output()
  remove = new EventEmitter();

  constructor(private http: HttpClient, private config: AppConfig) {}

  ngOnInit() {
    if (this.endpoint) {
      this.http
        .get(this.config.get('baseUrl') + this.endpoint)
        .pipe(map(res => res['results']))
        .subscribe(
          res => {
            this.suggestions = res;
            if (this.initial) {
              if (this.multi) {
                this.model = this.initial.length > 0 ? [...this.initial] : [];
              } else {
                this.model = String(this.initial);
              }
              this.select.emit(this.initial);
            }
          },
          err => console.log(err)
        );
    } else if (this.initial) {
      if (this.allowCustomValues && this.initial.length > 0) {
        this.suggestions = [...this.initial];
      }

      if (this.multi) {
        this.model = this.initial.length > 0 ? [...this.initial] : [];
      } else {
        this.model = String(this.initial);
      }
      this.select.emit(this.initial);
    }
  }

  onValueChange(event) {
    this.select.emit(event.target.value);
  }

  onRemove(event) {
    this.remove.emit(event.value);
  }
}
