import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Geolayer } from '../../shared/models/geolayer.model';
import { Geozone } from '../../shared/models/geozone.model';

@Component({
  selector: 'app-gis-layer-list',
  templateUrl: './layer-list.component.html',
  styleUrls: ['./layer-list.component.scss']
})
export class LayerListComponent implements OnInit {
  @Input()
  selected: Geolayer = null;

  @Input()
  layers: Geolayer[];

  @Input()
  soloLayers: boolean;

  @Output()
  toggleSoloLayers: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onToggleLayer: EventEmitter<Geolayer> = new EventEmitter();

  @Output()
  onZoneSelected: EventEmitter<Geozone> = new EventEmitter();

  @Output()
  editZone: EventEmitter<Geozone> = new EventEmitter();

  @Output()
  addZone = new EventEmitter();

  @Output()
  addLayer = new EventEmitter();

  @Output()
  editLayer: EventEmitter<Geolayer> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  toggleLayer(layer: Geolayer) {
    this.onToggleLayer.emit(layer);
  }

  onZoneClick(zone: Geozone) {
    this.onZoneSelected.emit(zone);
  }

  onAddZone() {
    this.addZone.emit();
  }

  onEditZone(zone: Geozone) {
    this.editZone.emit(zone);
  }
}
