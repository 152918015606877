import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from './../shared/shared.module';
import { RulesRoutingModule } from './rules-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RulesComponent } from './rules/rules.component';
import { RuleEditComponent } from './rule-edit/rule-edit.component';
import { FormsModule } from '@angular/forms';
import { RuleFormComponent } from './rule-edit/rule-form/rule-form.component';
import { AddParamsButtonComponent } from './rule-edit/add-params-button/add-params-button.component';
import { RuleTypeaheadComponent } from './rule-edit/rule-typeahead/rule-typeahead.component';
import { RuleGeolayersSelectComponent } from './rule-edit/rule-geolayers-select/rule-geolayers-select.component';

@NgModule({
  imports: [CommonModule, FormsModule, RulesRoutingModule, SharedModule, NgSelectModule],
  declarations: [
    RulesComponent,
    RuleEditComponent,
    RuleFormComponent,
    AddParamsButtonComponent,
    RuleTypeaheadComponent,
    RuleGeolayersSelectComponent
  ]
})
export class RulesModule {}
