import { AddGeozoneAction } from './../../core/store/actions/layer.actions';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Map as OlMap, View } from 'ol';
import sync from 'ol-hashed';
import { GeoJSON } from 'ol/format';
import { Draw, Modify } from 'ol/interaction';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { Geolayer } from '../../shared/models/geolayer.model';
import { Geometry } from '../../shared/models/geometry.model';
import { Geozone } from '../../shared/models/geozone.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { GeozoneRequest } from '../../shared/models/dto/request/add-geozone.model';

@Component({
  selector: 'app-gis-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {
  // fixed items
  private DEFAULT_OPACITY = 0.4;
  private formatter = new GeoJSON({
    featureProjection: 'EPSG:3857'
  });

  @Input()
  selectedLayer: Geolayer;
  @Input()
  layers: Geolayer[];

  @Input()
  soloLayers = false;

  @Output()
  changed = new EventEmitter<Geozone>();

  vectorLayers: Map<string, VectorLayer> = new Map<string, VectorLayer>();
  private styleCache = new Map<string, Style>();

  map: OlMap;
  private interactions = [];
  drawType = null;
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    // initialize the map with OSM
    const raster = new TileLayer({
      source: new OSM()
    });

    this.map = new OlMap({
      layers: [raster],
      target: 'map',
      view: new View({
        center: fromLonLat([4.511988, 51.3428753]),
        zoom: 12
      })
    });

    sync(this.map);
  }

  // manually watch for changes in #this.layers and #this.selectedLayer
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.layers && changes.layers.currentValue) {
      this.initLayers(changes.layers.currentValue);
    }

    if (changes && (changes.selectedLayer /* even when null */ || changes.soloLayers)) {
      this.updateModify(true);
    }
  }

  getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(
      pos => {
        this.setMapCenter(pos.coords.latitude, pos.coords.longitude, 12);
      },
      err => {
        console.log('We are not allowed access to your current location');
      }
    );
  }

  private initLayers(layers: Geolayer[]) {
    if (layers.length === 0 || !this.map) {
      return;
    }

    this.map
      .getLayers()
      .getArray()
      .filter(l => l instanceof VectorLayer)
      .forEach(l => this.map.removeLayer(l));

    this.vectorLayers = new Map<string, VectorLayer>();

    layers.forEach(layer => {
      const vs = new VectorSource();
      const vl = new VectorLayer({
        source: vs,
        opacity: layer === this.selectedLayer ? 1 : this.DEFAULT_OPACITY
      });

      this.vectorLayers.set(layer._id, vl);

      this.map.addLayer(vl);
      if (layer.zones) {
        layer.zones.forEach(zone => vs.addFeature(this.getFeatureFromZone(zone)));
      }

      // pointers changed, so change pointers
      if (this.selectedLayer && layer._id === this.selectedLayer._id) {
        this.selectedLayer = layer;
        this.updateModify(true);
      }
    });
  }

  private updateModify(fresh: boolean = true) {
    const vl = this.selectedLayer ? this.vectorLayers.get(this.selectedLayer._id) : null;
    this.highlightSelectedLayer(vl);

    this.setMode(vl ? new Modify({ source: vl.getSource() }) : null, fresh);
  }

  private highlightSelectedLayer(selected: Geolayer) {
    this.layers.forEach(l => {
      const vectorlayer = this.vectorLayers.get(l._id);
      if (vectorlayer) {
        vectorlayer.setOpacity(vectorlayer === selected ? 1 : this.soloLayers && selected ? 0 : this.DEFAULT_OPACITY);
      }
    });
  }

  /**
   * Sets the current interaction mode
   * @param interaction   interaction?, type of interaction; modify, draw, ..
   * @param fresh         boolean = false, remove all previous interactions
   */
  private setMode(interaction: any, fresh: boolean = false) {
    if (!this.map) {
      console.warn('Can not remove interactions from non existent map');
      return;
    }
    if (!interaction) {
      this.map.removeInteraction(this.interactions.pop());
      return;
    }

    if (fresh) {
      this.interactions.forEach(i => this.map.removeInteraction(i));
    }

    this.interactions.push(interaction);
    this.map.addInteraction(interaction);
  }

  addMode() {
    if (this.interactions.reduce((p, i) => p || i instanceof Draw, false)) {
      console.log('already drawing!');
      return;
    }

    this.drawType = 'Polygon';

    const source = new VectorSource();
    const vl = new VectorLayer({
      source: source
    });
    const draw = new Draw({
      type: this.drawType,
      source: source
    });
    this.map.addLayer(vl);
    this.vectorLayers.set('draw', vl);
    this.setMode(draw);

    source.on('change', () => {
      // remove layer and interactions
      this.setMode(null);
      const feature = source.getFeatures()[0];
      this.map.removeLayer(vl);
      this.vectorLayers.delete('draw');
      const color =
        '#' +
        Math.floor(Math.random() * 16777215)
          .toString(16)
          .padEnd(6, '0');

      this.store.dispatch(
        new AddGeozoneAction(
          new GeozoneRequest(
            this.selectedLayer._id,
            <Geometry>{
              type: this.drawType,
              coordinates: this.formatter.writeFeatureObject(feature).geometry.coordinates
            },
            color
          )
        )
      );
      this.drawType = null;
    });
  }

  onDrawTypeChanged(event) {
    this.setMode(null);

    this.setMode(
      new Draw({
        source: this.vectorLayers.get('draw').getSource(),
        type: this.drawType
      })
    );
  }

  private getFeatureFromZone(zone: Geozone) {
    const feature = this.formatter.readFeature({
      type: zone.geometry['type'],
      coordinates: zone.geometry['coordinates']
    });

    feature.setId(zone._id);
    feature.setStyle(this.getStyleFromCache(zone.color, zone.code));

    feature.on('change', () => {
      zone.geometry['coordinates'] = this.formatter.writeFeatureObject(feature).geometry.coordinates;
      this.addToChanged(zone);
    });

    return feature;
  }

  private addToChanged(zone: Geozone) {
    this.changed.emit(zone);
  }

  private getStyleFromCache(color: string, label: string) {
    if (!color) {
      color = '#FF0000';
    }

    const fill = new Fill({ color: color + '88' });
    const stroke = new Stroke({ color: color, width: 5 });

    // if (!this.styleCache[color]) {
    this.styleCache[color] = new Style({
      image: new Circle({
        fill: fill,
        stroke: stroke,
        radius: 10
      }),
      fill: fill,
      stroke: stroke,
      text: new Text({
        text: label,
        font: '2em Open Sans',
        fill: new Fill({ color: 'black' }),
        overflow: true
      })
    });
    // }

    return this.styleCache[color];
  }

  setMapCenter(lat, lng, zoom = 13) {
    this.map.getView().animate({
      center: fromLonLat([lng, lat]),
      zoom: zoom
    });
  }

  zoomToZone(zone: Geozone) {
    const extent = this.vectorLayers
      .get(this.selectedLayer._id)
      .getSource()
      .getFeatureById(zone._id)
      .getGeometry();

    this.map.getView().fit(extent, {
      duration: 400,
      maxZoom: 13
    });
  }
}
