import {User} from '../../../usermanagement/users/user.model';
import * as UserActions from '../actions/user.actions';
import {user} from '../types/user.types';

export function userReducer(state: User[] = [], action: UserActions.ALL) {
  switch (action.type) {
    case user.ADD:
      return [...state, action.payload];
    case user.DELETE:
      return state.filter(u => u !== <User>action.payload);
    case user.LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
