import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SwitchComponent } from './switch/switch.component';
import { ValuesPipe } from './values.pipe';
import { KeysPipe } from './keys.pipe';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [SidebarComponent, SwitchComponent, ValuesPipe, KeysPipe, DashboardComponent],
  exports: [SidebarComponent, SwitchComponent, ValuesPipe, KeysPipe]
})
export class SharedModule {}
