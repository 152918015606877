import { RuleParams } from '../rule-params.model';

export class GeofenceCondition implements RuleParams {
  public static CODE = 'Geofence';

  public code = GeofenceCondition.CODE;
  public options = [
    {
      key: 'triggers',
      type: 'select',
      options: ['entering', 'leaving', 'inside', 'outside'],
      multi: true,
      value: []
    },
    {
      key: 'geolayers',
      type: 'geolayers',
      endpoint: 'api/geolayers',
      includeZones: 'includeGeozones',
      excludeZones: 'excludeGeozones',
      value: {
        geolayers: [],
        includeGeozones: [],
        excludeGeozones: []
      }
    }
  ];

  constructor(public id: string = '', params: RuleParams[] = null) {
    if (params) {
      for (const opt of this.options) {
        switch (opt.key) {
          case 'geolayers':
            opt.value = {
              geolayers: params['geolayers'],
              includeGeozones: params['includedGeozones'],
              excludeGeozones: params['excludedGeozones']
            };
            break;
          default:
            opt.value = params[opt.key];
            break;
        }
      }
    }
  }

  validate(): boolean {
    let ok = true;

    for (const option of this.options) {
      switch (option.key) {
        case 'triggers':
          if (!option.value || option.value.toString() === '') {
            ok = false;
          }
          break;

        case 'geolayers':
          if (!this.checkGeolayers(option.value)) {
            ok = false;
          }
          break;
      }
    }

    return ok;
  }

  checkGeolayers(value): boolean {
    let ok = true;

    if (!value.geolayers || value.geolayers.length <= 0) {
      ok = false;
    }

    return ok;
  }

  getParameters(): any {
    const out = {};

    for (const opt of this.options) {
      switch (opt.key) {
        case 'geolayers':
          out['geolayers'] = opt.value['geolayers'];
          if (opt.value['includeZones']) {
            out['includedGeozones'] = opt.value['includeZones'];
          }
          if (opt.value['excludeZones']) {
            out['excludedGeozones'] = opt.value['excludeZones'];
          }
          break;
        default:
          out[opt.key] = opt.value;
          break;
      }
    }

    return out;
  }
}
