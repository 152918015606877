import { RuleEffects } from './store/effects/rule.effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { userReducer } from './store/reducers/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/effects/user.effects';
import { AuthGuard } from './auth-guard.service';
import { layerReducer } from './store/reducers/layer.reducer';
import { LayerEffects } from './store/effects/layer.effects';
import { ruleReducer } from './store/reducers/rule.reducer';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({ users: userReducer, layers: layerReducer, rules: ruleReducer }),
    StoreDevtoolsModule.instrument({}),
    EffectsModule.forRoot([UserEffects, LayerEffects, RuleEffects])
  ],
  declarations: [],
  providers: [AuthGuard]
})
export class CoreModule {}
