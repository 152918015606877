import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { RuleService } from '@services/rule.service';
import { rule } from '@store/types/rule.types';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  AddFailedAction,
  AddSuccessAction,
  DeleteFailedAction,
  DeleteSuccessAction,
  LoadFailedAction,
  LoadSuccessAction,
  UpdateSuccessAction
} from './../actions/rule.actions';

@Injectable()
export class RuleEffects {
  constructor(private actions$: Actions, private ruleService: RuleService) {}

  @Effect()
  getRules$: Observable<Action> = this.actions$.pipe(
    ofType(rule.LOAD),
    switchMap(action => this.ruleService.get()),
    map(res => new LoadSuccessAction(res)),
    catchError(err => of(new LoadFailedAction(err)))
  );

  @Effect()
  createRule$: Observable<Action> = this.actions$.pipe(
    ofType(rule.ADD),
    switchMap(action => this.ruleService.post(action['payload'])),
    map(res => new AddSuccessAction(res)),
    catchError(err => of(new AddFailedAction(err)))
  );

  @Effect()
  updateRule$: Observable<Action> = this.actions$.pipe(
    ofType(rule.UPDATE),
    switchMap(action => this.ruleService.put(action['payload'])),
    map(res => new UpdateSuccessAction(res)),
    catchError(res => of(new LoadFailedAction(res)))
  );

  @Effect()
  deleteRule$: Observable<Action> = this.actions$.pipe(
    ofType(rule.DELETE),
    tap(res => console.log('deleting', res)),
    switchMap(action => this.ruleService.delete(action['payload'])),
    map(res => new DeleteSuccessAction(res)),
    catchError(res => of(new DeleteFailedAction(res)))
  );
}
