import { RuleParamsResponseDto } from './rule-param.model';
import { RuleRequestDto } from './../request/rule.model';
import { Rule } from '../../../../rules/models/rule.model';

export class RuleResponseDto {
  /**
actions: []
conditions: []
createdAt: "2018-09-25T08:45:16+00:00"
enabled: false
excludedUnits: []
includedUnits: []
label: "New Rule"
triggers: [{id: "cf4a14d1-4cbf-4915-a681-89584d14ce59", code: "IncomingMessage", parameters: []}]
unitGroup: "59137bf6-be24-11e6-8369-525400a81f05"
updatedAt: "2018-09-25T08:45:16+00:00"
userContext: {id: "d564f609-b664-11e8-809f-5254002bb292"}
_id: "eefd76b7-9103-4761-a41b-f975a84e3007" */

  _id: string;
  label: string;
  unitGroup: string;
  enabled: boolean;
  triggers: RuleParamsResponseDto[];
  conditions: RuleParamsResponseDto[];
  actions: RuleParamsResponseDto[];

  static toRuleArray(dtos: RuleResponseDto[]): Rule[] {
    const out = [];

    for (const dto of dtos) {
      out.push(RuleResponseDto.toRule(dto));
    }

    return out;
  }

  static toRule(dto: RuleResponseDto): Rule {
    return {
      id: dto._id,
      label: dto.label,
      enabled: dto.enabled,
      unitGroup: dto.unitGroup,
      triggers: RuleParamsResponseDto.toArray(dto.triggers),
      conditions: RuleParamsResponseDto.toArray(dto.conditions),
      actions: RuleParamsResponseDto.toArray(dto.actions)
    } as Rule;
  }
}
