import { tap, catchError } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${this.authService.getToken()}`) });

    return next.handle(req).pipe(
      tap(
        event => {},
        err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.authService.clear();
            this.router.navigateByUrl('/login');
          }
        }
      )
    );
  }
}
