import { Action } from '@ngrx/store';
import { Rule } from '../../../rules/models/rule.model';
import { rule } from '../types/rule.types';

export class AddRuleAction implements Action {
  readonly type = rule.ADD;

  constructor(public payload: Rule) {}
}

export class AddSuccessAction implements Action {
  readonly type = rule.ADD_SUCCESS;

  constructor(public payload: Rule) {}
}

export class AddFailedAction implements Action {
  readonly type = rule.ADD_FAIL;

  constructor(public payload?: any) {}
}

export class DeleteRuleAction implements Action {
  readonly type = rule.DELETE;

  constructor(public payload: string) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = rule.DELETE_SUCCESS;

  constructor(public payload?: any) {}
}

export class DeleteFailedAction implements Action {
  readonly type = rule.DELETE_FAIL;

  constructor(public payload?: any) {}
}

export class LoadRulesAction implements Action {
  readonly type = rule.LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccessAction implements Action {
  readonly type = rule.LOAD_SUCCESS;

  constructor(public payload: Rule[]) {}
}

export class LoadFailedAction implements Action {
  readonly type = rule.LOAD_FAIL;

  constructor(public payload?: any) {}
}
export class UpdateRuleAction implements Action {
  readonly type = rule.UPDATE;

  constructor(public payload: Rule) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = rule.UPDATE_SUCCESS;

  constructor(public payload: Rule) {}
}

export class UpdateFailedAction implements Action {
  readonly type = rule.UPDATE_FAIL;

  constructor(public payload?: any) {}
}

export type ALL =
  | AddRuleAction
  | AddSuccessAction
  | AddFailedAction
  | DeleteRuleAction
  | DeleteSuccessAction
  | DeleteFailedAction
  | LoadRulesAction
  | LoadSuccessAction
  | LoadFailedAction
  | UpdateRuleAction
  | UpdateSuccessAction
  | UpdateFailedAction;
