export const rule = {
  ADD: '[RULE] add',
  ADD_SUCCESS: '[RULE] add successful',
  ADD_FAIL: '[RULE] add FAILED',

  DELETE: '[RULE] delete',
  DELETE_SUCCESS: '[RULE] delete successful',
  DELETE_FAIL: '[RULE] delete FAILED',

  LOAD: '[RULE] load',
  LOAD_SUCCESS: '[RULE] load successful',
  LOAD_FAIL: '[RULE] load FAILED',

  UPDATE: '[RULE] update',
  UPDATE_SUCCESS: '[RULE] update successful',
  UPDATE_FAIL: '[RULE] update FAILED'
};
