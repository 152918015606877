import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { MapComponent } from './map/map.component';
import { LayerListComponent } from './layer-list/layer-list.component';
import { ModalModule } from 'angular-custom-modal';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, AdminRoutingModule, FormsModule, ModalModule, ColorPickerModule, SharedModule],
  declarations: [AdminComponent, LayerListComponent, MapComponent]
})
export class AdminModule {}
