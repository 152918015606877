import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, mergeMap } from 'rxjs/operators';
import { Geolayer } from '../../../shared/models/geolayer.model';
import { LayerService } from '../../services/layer.service';
import {
  AddFailedAction,
  AddGeozoneFailedAction,
  AddGeozoneSuccessAction,
  AddSuccessAction,
  DeleteSuccessAction,
  LoadGeozonesAction,
  LoadGeozonesFailedAction,
  LoadGeozonesSuccessAction,
  LoadSuccessAction,
  SaveGeozoneFailedAction,
  SaveGeozoneSuccessAction,
  UpdateSuccessAction,
  DeleteGeozoneSuccessAction
} from '../actions/layer.actions';
import { layer } from '../types/layer.types';
import { AppState } from './../../../app.state';
import { ZoneService } from './../../services/zone.service';
import { DeleteFailedAction, UpdateFailedAction } from './../actions/layer.actions';

@Injectable()
export class LayerEffects {
  constructor(
    private actions$: Actions,
    private layerService: LayerService,
    private zoneService: ZoneService,
    private store: Store<AppState>
  ) {}

  @Effect()
  addLayer$: Observable<Action> = this.actions$.pipe(
    ofType(layer.ADD),
    switchMap(() => this.layerService.createRandom()),
    map(res => new AddSuccessAction(res as Geolayer)),
    catchError(err => of(new AddFailedAction(err)))
  );

  @Effect()
  deleteLayer$: Observable<Action> = this.actions$.pipe(
    ofType(layer.DELETE),
    switchMap(action => this.layerService.delete(action['payload'])),
    tap(res => console.log(res)),
    map(res => new DeleteSuccessAction(res)),
    catchError(err => of(new DeleteFailedAction(err)))
  );

  @Effect()
  loadLayers$: Observable<Action> = this.actions$.pipe(
    ofType(layer.LOAD),
    switchMap(() => this.layerService.get()), // .pipe(map(res => new LoadSuccessAction(res))))
    mergeMap(res => {
      const toPerform = <Array<Action>>res.map(l => new LoadGeozonesAction(l._id));
      toPerform.push(new LoadSuccessAction(res ? res : []));
      return toPerform;
    })
  );

  @Effect()
  updateLayer$: Observable<Action> = this.actions$.pipe(
    ofType(layer.UPDATE),
    mergeMap(action => this.layerService.patch(action['payload'])),
    map(res => new UpdateSuccessAction(res)),
    catchError(err => of(new UpdateFailedAction(err)))
  );

  @Effect()
  loadGeozoneForLayer$: Observable<Action> = this.actions$.pipe(
    ofType(layer.LOAD_ZONES),
    mergeMap(action => this.zoneService.get(action['payload'])),
    map(res => new LoadGeozonesSuccessAction(res)),
    catchError(err => of(new LoadGeozonesFailedAction(err)))
  );

  @Effect()
  addGeozoneToLayer$: Observable<Action> = this.actions$.pipe(
    ofType(layer.ADD_ZONE),
    switchMap(action => this.zoneService.createRandom(action['payload'])),
    map(res => new AddGeozoneSuccessAction(res)),
    catchError(err => of(new AddGeozoneFailedAction(err)))
  );

  @Effect()
  saveGeozone$: Observable<Action> = this.actions$.pipe(
    ofType(layer.SAVE_ZONE),
    mergeMap(action => this.zoneService.put(action['payload'])),
    map(res => new SaveGeozoneSuccessAction(res)),
    catchError(err => of(new SaveGeozoneFailedAction(err)))
  );

  @Effect()
  deleteGeozone$: Observable<Action> = this.actions$.pipe(
    ofType(layer.DELETE_ZONE),
    mergeMap(action => this.zoneService.delete(action['payload'])),
    map(res => new DeleteGeozoneSuccessAction(res)),
    catchError(err => of(new DeleteFailedAction(err)))
  );
}
