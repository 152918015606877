import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const valArr: any[] = [];

    for (const val of Object.keys(value)) {
      valArr.push(value[val]);
    }

    return valArr;
  }
}
