import { AppConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Layer } from '../../shared/layer.model';
import { map, tap } from 'rxjs/operators';
import { Geolayer } from '../../shared/models/geolayer.model';

@Injectable({
  providedIn: 'root'
})
export class LayerService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  get(): Observable<Geolayer[]> {
    const params = new HttpParams().set('limit', '20');
    return this.http
      .get(this.config.get('baseUrl') + 'api/geolayers', { params })
      .pipe(map(res => <Geolayer[]>res['results']));
  }

  patch(layer: Geolayer): Observable<Geolayer> {
    return this.http.patch(this.config.get('baseUrl') + `api/geolayers/${layer._id}`, layer).pipe(map(res => layer));
  }

  createRandom(): Observable<Geolayer> {
    const code = (Math.random() * 12345678).toString(16);
    return this.http
      .post(this.config.get('baseUrl') + 'api/geolayers', {
        code: code.toUpperCase(),
        label: code,
        weight: 1
      })
      .pipe(map(res => <Geolayer>res));
  }

  delete(layer_id: string): Observable<any> {
    return this.http.delete(this.config.get('baseUrl') + `api/geolayers/${layer_id}`).pipe(map(res => layer_id));
  }

  test(): Observable<Geolayer[]> {
    return this.http.get('/assets/api/test.json').pipe(
      tap(res => (<Geolayer[]>res).sort((a, b) => a.weight - b.weight)),
      map(res => <Geolayer[]>res)
    );
  }
}
