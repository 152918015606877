import {Action} from '@ngrx/store';
import {User} from '../../../usermanagement/users/user.model';
import {user} from '../types/user.types';


export class AddUserAction implements Action {
  readonly type = user.ADD;

  constructor(public payload: User) {
  }
}

export class AddSuccessAction implements Action {
  readonly type = user.ADD_SUCCESS;

  constructor(public payload: User) {
  }
}

export class DeleteUserAction implements Action {
  readonly type = user.DELETE;

  constructor(public payload: User) {
  }
}

export class DeleteSuccessAction implements Action {
  readonly type = user.DELETE_SUCCESS;

  constructor() {
  }
}

export class LoadUsersAction implements Action {
  readonly type = user.LOAD;

  constructor(public payload?: any) {
  }
}

export class LoadSuccessAction implements Action {
  readonly type = user.LOAD_SUCCESS;

  constructor(public payload: User[]) {
  }
}

export type ALL = AddUserAction | DeleteUserAction | LoadUsersAction | LoadSuccessAction;
