import { filter, map, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RuleParameter } from '../../models/rule-parameter.model';
import { ZoneService } from './../../../core/services/zone.service';

@Component({
  selector: 'app-gis-rule-geolayers-select',
  templateUrl: './rule-geolayers-select.component.html',
  styleUrls: ['./rule-geolayers-select.component.scss']
})
export class RuleGeolayersSelectComponent implements OnInit {
  @Input()
  params: RuleParameter;

  @Output()
  select = new EventEmitter<any>();

  geolayers: string[] = [];
  includezones: string[] = null;
  excludezones: string[] = null;

  // this is the only (dirty) way I could think of, because I made the typeahead event-base (not model binding)
  // setting one of these values to an id will remove it from the typeahead model
  removeIncludeZone = null;
  removeExcludeZone = null;

  layersWithZones = {};
  zonesToSuggest = [];

  constructor(private zoneService: ZoneService) {}

  ngOnInit() {
    this.includezones = this.params.value.includeGeozones;
    this.excludezones = this.params.value.excludeGeozones;
  }

  onGeolayerSelect(layerIds) {
    this.geolayers = layerIds;
    this.zonesToSuggest = [];

    this.returnValues();

    for (const key of this.geolayers) {
      if (this.layersWithZones[key]) {
        this.zonesToSuggest.push(...this.layersWithZones[key]);
      }
    }

    for (const layerId of layerIds) {
      if (!this.layersWithZones[layerId]) {
        this.layersWithZones[layerId] = [];
        this.zoneService.get(layerId).subscribe(zones => {
          this.layersWithZones[layerId] = zones;
          this.onGeolayerSelect(layerIds);
        });
      }
    }
  }

  onLayerRemove(layer) {
    this.checkIfZonesForbidden(this.includezones, layer._id).forEach(remove => {
      this.removeIncludeZone = remove; // this triggers the setter of the rule-typeahead component
      this.includezones = this.includezones.filter(z => z !== remove);
    });
    this.checkIfZonesForbidden(this.excludezones, layer._id).forEach(remove => {
      this.removeExcludeZone = remove;
      this.excludezones = this.excludezones.filter(z => z !== remove);
    });

    this.returnValues();
  }

  private checkIfZonesForbidden(zones, layerId) {
    const _ids = <Array<String>>this.layersWithZones[layerId].map(z => z._id);
    const toRemove = Array();

    zones.forEach(z => {
      if (_ids.indexOf(z) >= 0) {
        toRemove.push(z);
      }
    });

    return toRemove;
  }

  onIncludeZones(zones) {
    this.includezones = zones;
    this.returnValues();
  }

  onExcludeZones(zones) {
    this.excludezones = zones;
    this.returnValues();
  }

  returnValues() {
    const obj = {
      geolayers: this.geolayers
    };

    if (this.includezones && this.includezones.length > 0) {
      obj['includeZones'] = this.includezones;
    } else if (this.excludezones && this.excludezones.length > 0) {
      obj['excludeZones'] = this.excludezones;
    }

    this.select.emit(obj);
  }
}
