import { Rule } from './../../../../rules/models/rule.model';
import { RuleParamsDto } from './rule-params.model';
export class RuleRequestDto {
  public label: string;
  public enabled: boolean;
  public triggers = [];
  public conditions = [];
  public actions = [];
  public unitGroup: string;

  constructor(rule: Rule) {
    this.label = rule.label;
    this.enabled = rule.enabled;
    this.triggers = RuleParamsDto.fromArray(rule.triggers);
    this.conditions = RuleParamsDto.fromArray(rule.conditions);
    this.actions = RuleParamsDto.fromArray(rule.actions);
    this.unitGroup = rule.unitGroup;
  }
}
