import { RuleParameter } from './../../models/rule-parameter.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Rule } from '../../models/rule.model';
import { RuleParams } from '../../models/rule-params.model';
import { isNull } from 'util';

@Component({
  selector: 'app-gis-rule-form',
  templateUrl: './rule-form.component.html',
  styleUrls: ['./rule-form.component.scss']
})
export class RuleFormComponent implements OnInit {
  collapsed = true;

  @Input()
  ruleParams: RuleParams;
  @Output()
  delete = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  addEmptyParam(name: string) {
    if (isNull(this.ruleParams[name])) {
      this.ruleParams[name] = new Array<RuleParameter>();
    }

    this.ruleParams[name].push({});
  }
}
