import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserService} from '../../services/user.service';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {LoadSuccessAction, LoadUsersAction} from '../actions/user.actions';
import {map, switchMap} from 'rxjs/operators';
import {user} from '../types/user.types';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private userService: UserService) {
  }

  @Effect()
  loadUsers$: Observable<Action> = this.actions$.pipe(
    ofType(user.LOAD),
    map((action: LoadUsersAction) => action.payload),
    switchMap(() => this.userService.get().pipe(
      map(res => new LoadSuccessAction(res))
    ))
  );
}
